import React, { Component } from 'react';

function KuplootusVideo () {
  return <iframe width="300" src="https://www.youtube.com/embed/vGVabItRvBg?start=100" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
}

function Navigation () {
  return  <nav class="navbar navbar-expand-md bg-dark navbar-dark">
  <a class="navbar-brand" href="/"><img src="/kuplootus.png" height="30"></img></a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="/">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/">Tech</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/">Blog</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/">Contact</a>
      </li> 
    </ul>
  </div> 
</nav> 
}

class InvitationLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { invitationnumber: '' };
  }
  myChangeHandler = (event) => {
    this.setState({invitationnumber: event.target.value});
  }
  mySubmitHandler = (event) => {
    event.preventDefault();

    const encodedValue = encodeURIComponent(this.state.invitationnumber);

    fetch(`https://api.kuplootus.com/invitation/invitation?number=${encodedValue}`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      this.setState({status: data.status});
      this.setState({message: data.message});
      this.setState({promoid: data.promoid});
      console.log(data);
    })
    .catch((error) => {
      this.setState({error: error});
      console.error('Error:', error);
    });
  }
  render() {
    return (
      <div>
      <form onSubmit={this.mySubmitHandler} class="needs-validation" novalidate>
        <div class="form-group">
          <label for="invitationnumber">Invitation Number</label>
          <input type='text' onChange={this.myChangeHandler} class="form-control" id="invitationnumber" placeholder="Enter Invitation Number" name="inviationnumber" required/>
        </div>
        <input type='submit' class="btn btn-primary"></input>
      </form>
        <p><small>Try entering in A062100043.</small></p>
    <table class="table table-dark">
      <thead>
        <tr>
          <th>Status</th>
          <th>Message</th>
          <th>Promo ID</th>
          <th>Error</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{this.state.status}</td>
          <td>{this.state.message}</td>
          <td>{this.state.promoid}</td>
          <td>{this.state.error}</td>
        </tr>
      </tbody>
    </table>
</div>
      );
  }
}

    class App extends Component {
      render() {
        return (
          <div class="container">
            <Navigation />
            <div class="jumbotron text-center">
              <h1>Welcome to Kuplootus</h1>
              <p>My Technical Playground</p> 
          </div>
              <h4>Invitation API</h4>
              <InvitationLookup />
              <h4>Where is Kuplootus?</h4>
              <KuplootusVideo />
        </div>
        );
      }
    }

    export default App;
